import {yupResolver} from "@hookform/resolvers/yup"
import {number, object, string} from "yup"

export interface IProductForm {
    product_name: string,
    code: string,
    company_id: string,
    fee_fix_value: string,
    fee_percentage: string,
    status: boolean,
    fee_type: string,
}

export const defaultValues = {
    product_name: '',
    code: '',
    company_id: '',
    fee_fix_value: '',
    fee_percentage: '',
    status: true,
    fee_type: '0',
}

export const resolver = yupResolver(
    object({
        product_name: string().required('Required field'),
        code: string().required('Required field').min(4, 'Minimum 4 characters'),
        company_id: number().transform(value => (isNaN(value) ? undefined : value)).required('Required field'),
        fee_type: string(),
        fee_fix_value: number().transform(value => (isNaN(value) ? 0 : value)).min(0, 'Must be greater than or equal to 0'),
        fee_percentage: number()
            .transform(value => (isNaN(value) ? 0 : value))
            .min(0, 'Must be greater than or equal to 0')
            .max(100, 'Must be less than or equal to 100'),
    })
)
