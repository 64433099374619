import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FormProvider, useForm} from 'react-hook-form';
import {InputField, SelectField, SwitchField} from '../../components/fields/RenderField';
import {alertError, alertSuccess} from '../../utils/alert';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import LoadingButton from '@mui/lab/LoadingButton';
import {Save} from '@mui/icons-material';
import product from '../../redux/actions/product';
import company from '../../redux/actions/company';
import {defaultValues, IProductForm, resolver} from './models/form';
import { feeType } from './settings/ref';

const FormDialog = ({open, handleClose, data}: any) => {
    const methods = useForm<IProductForm>({resolver});
    // const adminFeeType = methods.watch('fee_type');

    const loading = useAppSelector((state) => state.product.isLoadingSubmit);
    const params = useAppSelector((state) => state.product.params);
    const companyList = useAppSelector((state) => state.company.dataAll);
    const dispatch = useAppDispatch();

    // const [isAdminFeeTypeFix, setIsAdminFeeTypeFix] = React.useState<boolean>(
    //     data.fee_fix_value > 0 ? true : false
    // );

    const onSubmit = async (value: any) => {
        try {
            if (data.id) {
                await dispatch(product.updateData(data.id, value));
            } else {
                await dispatch(product.createData(value));
            }
            alertSuccess(`Product berhasil di ${data.id ? 'update' : 'tambahkan'}`).then(() => {
                handleClose();
                dispatch(product.fetchList(params));
            });
        } catch (error: any) {
            alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
        }
    };

    // React.useEffect(() => {
    //     if (adminFeeType === '1') {
    //         setIsAdminFeeTypeFix(false);
    //         methods.setValue('fee_fix_value', '');
    //     } else {
    //         setIsAdminFeeTypeFix(true);
    //         methods.setValue('fee_percentage', '');
    //     } 
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [adminFeeType]);

    React.useEffect(() => {
        dispatch(company.fetchAll());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (data.id && open === true) {
            methods.reset({...data, code: data?.product_code, fee_type: data.fee_fix_value > 0 ? '0' : '1' });
        } else {
            methods.reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <div>
            <Dialog
                open={open}
                maxWidth="xs"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogTitle id="alert-dialog-title">{data.id ? 'Edit' : 'Add'} Product</DialogTitle>
                    <DialogContent>
                        <FormProvider {...methods}>
                            <SelectField
                                name="company_id"
                                label="Company"
                                options={companyList.map((data: any) => ({value: data.id, label: data.name}))}
                            />
                            <InputField name="product_name" label="Product Name" maxLength={100} pattern={/^[\w. ]*$/i} />
                            <InputField name="code" label="Product Code" maxLength={6} pattern={/^[\w ]*$/i} />
                            {/* <SelectField
                                name="fee_type"
                                label="Transaction Fee Type"
                                options={feeType}
                                defaultValue="0"
                            /> */}
                            <InputField name="fee_fix_value" label="Transaction Fee (Fix)" type="number" />
                            <InputField name="fee_percentage" label="Transaction Fee (%)" type="number" max={100} />
                            <SwitchField name="status" label="Aktif"/>
                        </FormProvider>
                    </DialogContent>
                    <DialogActions sx={{px: 3, pb: 3}}>
                        <Button onClick={handleClose} variant="outlined" type="button">
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<Save/>}
                            type="submit"
                            variant="contained">
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default FormDialog;
