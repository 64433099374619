import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid} from '@mui/material';
import {formatDateTime} from '../../utils/time';
import {formatMoney, formatSentence} from '../../utils/string';
import {SpanItem} from '../../components/fields/ItemDetail';
import { cashInDetailColumns } from './models/columns';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useEffect } from 'react';
import cashIn from '../../redux/actions/cash-in';
import DataTableDetail from '../../components/table/DataTableDetail';

const CashInDetailDialog = ({dataSelected, open, handleClose}: any) => {
    const propsSpanItem: any = { widthLabel: 4, widthValue: 8 };
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.cashIn);

    useEffect(() => {
        if (dataSelected) {
            dispatch(cashIn.fetchDetailItem({ page: 1, limit: 10, id: dataSelected.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSelected]);


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Transaction Detail</DialogTitle>
                <DialogContent>
                    <Grid container mb={1}>
                        <Grid item sm={5}>
                            <SpanItem label="Customer Name" value={dataSelected?.customer_name}/>
                            <SpanItem label="Company Name" value={dataSelected?.company_name}/>
                            <SpanItem label="Product Name" value={dataSelected?.product_name}/>
                            <SpanItem label="Payment Provider" value={dataSelected?.payment_provider}/>
                            <SpanItem label="Payment Channel" value={dataSelected?.payment_channel}/>
                            <SpanItem label="Created Date" value={formatDateTime(dataSelected?.create_date)}/>
                            <SpanItem
                                label="Settlement Date"
                                value={formatDateTime(dataSelected?.payment_at)}
                            />
                            {/* <SpanItem
                                label="Reconciled Date"
                                value={formatDateTime(dataSelected?.reconciled_date)}
                            />
                            <SpanItem
                                label="Disbursement Date"
                                value={formatDateTime(dataSelected?.disbursement_date)}
                            />
                            <SpanItem
                                label="Disbursement Status"
                                value={formatSentence(dataSelected?.disbursement_status)}
                            />
                            <SpanItem
                                label="Outstanding"
                                value={dataSelected?.is_outstanding_cash_out ? 'Yes' : 'no'}
                            /> */}
                        </Grid>
                        <Grid item sm={7}>
                            <SpanItem {...propsSpanItem} label="Invoice Number" value={dataSelected?.invoice_number}/>
                            <SpanItem
                                {...propsSpanItem}
                                label="Cash In Status"
                                value={formatSentence(dataSelected?.status, '-')}
                            />
                            <SpanItem {...propsSpanItem} label="Grand Total" value={formatMoney(dataSelected?.grand_total)}/>
                            <SpanItem {...propsSpanItem} label="Subtotal Item" value={formatMoney(dataSelected?.sub_total_item)}/>
                            <SpanItem {...propsSpanItem} label="Cash In Fee" value={formatMoney(dataSelected?.admin_fee)}/>
                            <SpanItem {...propsSpanItem} label="Transaction Fee" value={formatMoney(dataSelected?.product_fee)}/>
                            {/* <SpanItem {...propsSpanItem} label="Partner Fee" value={formatMoney(dataSelected?.partner_fee)}/> */}
                            <SpanItem {...propsSpanItem} label="Delivery Fee" value={formatMoney(dataSelected?.delivery_fee)}/>
                            <SpanItem {...propsSpanItem} label="Total Cash In" value={formatMoney(dataSelected?.cash_in_total)}/>
                            <SpanItem {...propsSpanItem} label="Discount" value={formatMoney(dataSelected?.voucher)}/>
                            {/* <SpanItem {...propsSpanItem} label="Batch" value={dataSelected?.batch_number_cash_out || '-'}/> */}
                        </Grid>
                    </Grid>
                    <DataTableDetail
                        columns={cashInDetailColumns()}
                        data={data}
                        loading={data.isLoadingDetail}
                        fetchList={cashIn.fetchDetailItem}
                        showTotal
                    />
                </DialogContent>
                <DialogActions sx={{px: 3, pb: 3}}>
                    <Button onClick={handleClose} variant="outlined" type="button">
                        Oke
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CashInDetailDialog;
