import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemText,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/system';
import { MouseEvent, ReactNode, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getProperty } from '../../utils/object';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DateTimePicker from '@mui/lab/DateTimePicker';
import moment from 'moment';

interface IInputProps {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string | number;
  endAdornment?: ReactNode;
  maxLength?: number;
  autocomplete?: string;
  rows?: number;
  max?: number;
  min?: number;
  style?: any;
  minLength?: number;
  pattern?: RegExp;
}

export const InputField = (props: IInputProps) => {
  const {
    label,
    name,
    type,
    placeholder,
    disabled,
    defaultValue = '',
    endAdornment,
    maxLength = 35,
    minLength = 0,
    max,
    min,
    style,
    pattern,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box sx={{ mb: 2 }} style={style}>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field }) => (
          <TextField
            fullWidth
            multiline={!!props.rows}
            minRows={props.rows}
            maxRows={props.rows}
            autoComplete={props.autocomplete}
            placeholder={placeholder || (type === 'number' ? '0' : label)}
            disabled={disabled}
            type={type || 'text'}
            size="small"
            error={!!getProperty(name, errors)}
            helperText={getProperty(name, errors)?.message}
            InputProps={{
              endAdornment,
            }}
            inputProps={{
              maxLength: maxLength === 0 ? undefined : maxLength,
              minLength: minLength === 0 ? undefined : minLength,
              max: max === undefined || max === null ? undefined : max,
              min: min === undefined || min === null ? undefined : min,
            }}
            {...field}
            onChange={e => {
              if (pattern) {
                if (e.target.value === '' || pattern.test(e.target.value)) {
                  field.onChange(e.target.value);
                }
              }
              else field.onChange(e.target.value);
            }}
          />
        )}
        defaultValue={defaultValue}
        name={name}
        control={control}
      />
    </Box>
  );
};

export const InputDateTimeField = (props: any) => {
  const { label, name, defaultValue = '', format, disablePast, disableFuture, PopperProps } = props;
  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const [dateValue, setDateValue] = useState<Date | null>(null);

  const handleChange = (value: Date | null) => {
    setDateValue(value);
    setValue(name, moment(value).format(format));
    clearErrors(name);
  };
  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field }) => (
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DateTimePicker
              value={dateValue}
              disablePast={disablePast}
              disableFuture={disableFuture}
              onChange={(newValue) => handleChange(newValue)}
              inputFormat="DD/MM/YYYY HH:mm"
              PopperProps={PopperProps}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  error={!!getProperty(name, errors)}
                  helperText={getProperty(name, errors)?.message}
                />
              )}
            />
          </LocalizationProvider>
        )}
        defaultValue={defaultValue}
        name={name}
        control={control}
      />
    </Box>
  );
};

export const InputPasswordField = (props: IInputProps) => {
  const { label, name, type, placeholder, disabled, defaultValue = '', maxLength } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field }) => (
          <TextField
            fullWidth
            autoComplete={props.autocomplete}
            placeholder={placeholder || (type === 'number' ? '0' : label)}
            disabled={disabled}
            type={showPassword ? 'text' : 'password'}
            size="small"
            error={!!errors[name]}
            helperText={errors[name]?.message}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="small">
                  {showPassword ? (
                    <VisibilityOff fontSize="small" />
                  ) : (
                    <Visibility fontSize="small" />
                  )}
                </IconButton>
              ),
            }}
            inputProps={{
              maxLength: maxLength || 35,
            }}
            {...field}
          />
        )}
        defaultValue={defaultValue}
        name={name}
        control={control}
      />
    </Box>
  );
};

export const SelectField = (props: any) => {
  const { label, name, options, disabled, defaultValue = '', nullValue = '' } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <Box sx={{ mb: 2 }}>
          <InputLabel>{label}</InputLabel>
          <Select
            fullWidth
            displayEmpty
            size="small"
            disabled={disabled}
            error={!!getProperty(name, errors)}
            {...field}>
            <MenuItem value={nullValue}>
              <em>{`- Select ${label} -`}</em>
            </MenuItem>
            {options.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: '#d32f2f' }}>
            {getProperty(name, errors)?.message}
          </FormHelperText>
        </Box>
      )}
      name={name}
      defaultValue={defaultValue}
      control={control}
    />
  );
};

export const AutocompleteField = (props: any) => {
  const { label, name, options, value, onChange, placeholder } = props;

  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel>Company</InputLabel>
      <Autocomplete
        multiple
        value={value}
        onChange={(e, v) => onChange(v)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        size="small"
        limitTags={1}
        id="multiple-limit-tags"
        options={options}
        getOptionLabel={(option: { label: string; value: number }) => option.label}
        fullWidth
        defaultValue={[]}
        renderInput={(params) => (
          <TextField {...params} name={name} placeholder={placeholder || label} />
        )}
      />
    </Box>
  );
};

interface IOption {
  value: any;
  label: any;
}

interface ISelectProps {
  name: string;
  options: IOption[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string | number;
}

export const MultipleSelectField = (props: ISelectProps) => {
  const { label, name, options, disabled, defaultValue = '' } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <Box sx={{ mb: 2 }}>
          <InputLabel>{label}</InputLabel>
          <Select
            fullWidth
            displayEmpty
            size="small"
            disabled={disabled}
            error={!!getProperty(name, errors)}
            multiple
            renderValue={(selected) => {
              return (
                selected?.map((option: any) => option.label).join(', ') || `- Pilih ${label} -`
              );
            }}
            {...field}>
            {options.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {/* <Checkbox size="small" checked={field.value.map((val: any) => val.value).indexOf(item.value) > -1} /> */}
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: '#d32f2f' }}>
            {getProperty(name, errors)?.message}
          </FormHelperText>
        </Box>
      )}
      name={name}
      defaultValue={defaultValue}
      control={control}
    />
  );
};

export const RadioField = (props: any) => {
  const { label, name, options } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Box sx={{ mb: 2 }}>
      <FormControl component="fieldset" error={!!errors[name]}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup aria-label={label} defaultValue="">
          {options.map((item: any, index: number) => (
            <FormControlLabel
              key={index}
              value={item.value}
              control={<Radio sx={{ py: '4px' }} size="small" {...register(name)} />}
              label={item.label}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{errors[name]?.message}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export const CheckboxGroupField = (props: any) => {
  const { label, name, options } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Box sx={{ mb: 2 }}>
      <FormControl error={!!errors[name]} component="fieldset" variant="standard">
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup>
          {options.map((item: any, index: number) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox sx={{ py: '4px' }} size="small" {...register(name)} value={item.value} />
              }
              label={item.label}
            />
          ))}
        </FormGroup>
        <FormHelperText>{errors[name]?.message}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export const CheckboxField = (props: any) => {
  const { label, name, defaultValue = false } = props;
  const { control } = useFormContext();
  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <Checkbox
                  size="small"
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
          }
          label={label}
        />
      </FormGroup>
    </Box>
  );
};

export const SwitchVerticalField = (props: any) => {
  const { label, name } = props;
  const { control } = useFormContext();
  return (
    <Box sx={{ mb: 2 }}>
      <FormGroup>
        <FormLabel component="legend">{label}</FormLabel>
        <FormControlLabel
          control={
            <Controller
              name={name}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  size="small"
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
          }
          label="&nbsp;"
          sx={{ ml: -0.5, mt: 1 }}
        />
      </FormGroup>
    </Box>
  );
};

export const SwitchField = (props: any) => {
  const { label, name } = props;
  const { control } = useFormContext();
  return (
    <Box sx={{ mb: 2, ml: 1 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Controller
              name={name}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  size="small"
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
          }
          label={label}
        />
      </FormGroup>
    </Box>
  );
};
